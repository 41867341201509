import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React from 'react';
import useUpdateListener from '../../use-update-listener';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW, LexicalEditor, createCommand } from 'lexical';
import styled from 'styled-components';
import Dropdown from 'components/admin2/ui/Dropdown';
import { ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_6, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import { TOOLBAR_BUTTON_ACTIVE_CSS } from '../../styles';
import { $getSelectionStyleValueForProperty, $patchStyleText } from '@lexical/selection';
import useFonts from 'hooks/use-fonts';

const APPLY_FONT_FAMILY_COMMAND = createCommand<{ fontFamily: string; }>('APPLY_FONT_FAMILY_COMMAND');

const applyFontFamily = (editor: LexicalEditor, fontFamily: string) => {
  editor.update(() => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
      return;
    }

    $patchStyleText(selection, {
      'font-family': fontFamily,
    });
  });
};

const useFontFamilyPluginRegisters = () => {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    return editor.registerCommand(APPLY_FONT_FAMILY_COMMAND, ({ fontFamily }) => {
      applyFontFamily(editor, fontFamily);
      return true;
    }, COMMAND_PRIORITY_LOW);
  }, []);
};

const FontFamilyPlugin = () => {
  useFontFamilyPluginRegisters();
  const fonts = useFonts();
  const [editor] = useLexicalComposerContext();
  const [fontFamily, setFontFamily] = React.useState('');

  const handleChangeFontFamily = React.useCallback((option: ReturnType<typeof useFonts>[number]) => {
    if (fontFamily === option.value) {
      return;
    }
    editor.dispatchCommand(APPLY_FONT_FAMILY_COMMAND, {
      fontFamily: option.value,
    });
  }, [fontFamily, editor]);

  const getValue = React.useCallback((value: string) => fonts.find((option) => option.value === value), [fonts]);

  const update = () => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
      return;
    }

    const value = $getSelectionStyleValueForProperty(selection, 'font-family', '');
    if (value && value !== fontFamily) {
      setFontFamily(value);
    }
  };
  useUpdateListener(editor, update);

  return (
    <FontFamilyDropdown
      onChange={handleChangeFontFamily}
      options={fonts}
      value={getValue(fontFamily)}
      placeholder="Font Family"
    />
  );
};

export const FontFamilyDropdown = styled(Dropdown).attrs({
  padding: '0',
  labelPadding: '0 0 10px 0',
})`
  width: 173px;
  height: 40px;
  background: transparent;

  & .dropdown-button {
    min-height: 40px;
    max-height: 40px;
    border-radius: 8px;
    border: 1px solid ${ADMIN_SURFACE_6};
    color: ${ADMIN_TEXT_100};
    ${ADMIN_TEXT_LABEL_M_MEDIUM}
  }

  & .dropdown-title {
    line-height: unset;
  }

  &:hover {
    ${TOOLBAR_BUTTON_ACTIVE_CSS}

    & .dropdown-button {
      border-color: ${ADMIN_ACCENT_PRIMARY};
    }
  }
` as typeof Dropdown;

export default FontFamilyPlugin;
